<template>
  <div class="donations template-1">
    <header class="page-header container">
      <div class="dib">
        <h1>{{ pageTitle }}</h1>
      </div>
    </header>
    <div v-if="!allowPage">
      <security-banner :i18n="translations.components"></security-banner>
    </div>
    <div v-if="allowPage">
      <page-body>
        <b class="campname" v-if="stateOrCamp === 'c'">{{ currentCamp.text }} {{ translations.tcCamp }} - {{
          currentCamp.value }}</b>
        <b class="campname" v-if="stateOrCamp === 's'">{{ stateUndist.camp_name }} - {{ stateUndist.camp_number }}</b>
        <section class="section-1">
          <div class="dib ml30" v-if="showTable === '2'">
            <div id="dpContainer" class="dp-container">
              <div id="dpStart" class="dp-startend mr-2">
                <b-form-datepicker id="startDate" v-model="selectedDateRange.start" class="mb-2 contact_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="prefCulture"
                  :label-help="translations.tcCalHelp" :label-no-date-selected="translations.tcNoDateSelected"
                  :placeholder="translations.tcStartDate" :min="minimumDate" :max="new Date()"
                  :show-decade-nav="showDecadeNav" :hide-header="hideHeader" size="sm">
                  <template #button-content style="padding: 12px">
                    <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                  </template>
                </b-form-datepicker>
              </div>
              <div id="dpEnd" class="dp-startend mr-2">
                <b-form-datepicker id="endDate" v-model="selectedDateRange.end" class="mb-2 contact_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="prefCulture"
                  :label-help="translations.tcCalHelp" :label-no-date-selected="translations.tcNoDateSelected"
                  :placeholder="translations.tcEndDate" :min="minimumDate" :max="new Date()"
                  :show-decade-nav="showDecadeNav" :hide-header="hideHeader" size="sm">
                  <template #button-content style="padding: 12px">
                    <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                  </template>
                </b-form-datepicker>
              </div>
              <div class="col-md-2">
                <b-button size="sm" class="" variant="primary" :disabled="!readyToSubmit" @click="handleHistoryRequest">{{
                  translations.tcSubmit }}</b-button>
              </div>
            </div>
          </div>
          <b-container v-if="showTable === '1'">
            <b-row>
              <b-col sm="12">
                <b-alert :show="dismissCountDown" dismissible :variant="alertVariant" @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged">{{ alertMessage }}</b-alert>
              </b-col>
            </b-row>
            <b-row class="hdr flex-nowrap">
              <b-col sm="3" md="1" lg="1" class="padrspn"><b>{{ translations.tcDate }}</b></b-col>
              <b-col sm="3" md="2" lg="2" class="padrspn"><b>{{ translations.tcType }}</b></b-col>
              <b-col sm="3" md="2" lg="2" class="padrspn"><b>{{ translations.tcStatus }}</b></b-col>
              <b-col sm="3" md="2" lg="2" class="padrspn"><b>{{ translations.tcTotal }}</b></b-col>
              <b-col sm="3" md="4" lg="4" class="padrspn d-none d-sm-block"></b-col>
            </b-row>
            <b-row class="lh54" v-if="summaryItems.length === 0">
              <b-col sm="12">{{ translations.tcNoDataToDisplay }}.</b-col>
            </b-row>
            <b-row class="lh54 flex-nowrap" hover v-for="item in summaryItems" :key="item.Key">
              <b-col sm="12">
                <b-row hover class="lh54 up flex-nowrap">
                  <b-col sm="3" md="1" lg="1" class="padrspn mobfont">{{ convertDate(item.Date) }}</b-col>
                  <b-col sm="3" md="2" lg="2" class="padrspn mobfont">{{ item.Type }}</b-col>
                  <b-col sm="3" md="2" lg="2" class="padrspn mobfont">{{ item.Status }}</b-col>
                  <b-col sm="3" md="2" lg="2" class="padrspn mobfont">${{ parseFloat(item.Total).toFixed(2) }}</b-col>
                  <b-col md="4" lg="4" class="d-none d-sm-block">
                    <b-button size="sm" v-if="hasBeen10Minutes(item.SortDate)"
                      @click="handleDetailsClick(item.Key, showTable, item.Multicamp)" variant="info" class="mr-2">{{
                        translations.tcDetails }}</b-button>
                    <b-button v-if="item.Status === `Pending` &&
                      hasBeen10Minutes(item.SortDate) &&
                      iCanSee(
                        secured_treasury_tool_pending_delete_controls.delete_treasury_tool_pending_transaction_button
                      )
                      " size="sm" @click="handleDeleteClick(item)" variant="danger" class="mr-2">{{
    translations.tcDelete }}</b-button>
                  </b-col>
                  <b-col sm="0" md="1" lg="1" class="d-none d-sm-block"
                    v-b-popover.hover.top="helpMessagePrefix + item.Error || ''"
                    v-if="item.Error !== null && item.Error !== ''" title="Attention">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 100 100" stroke="#000">
                      <path d="M8,80s-5,8,5,9l78,0s9,0,5-9l-40-71s-4-6-8,0z" stroke-width="2" fill="#fff"
                        fill-rule="evenodd" />
                      <path d="M52,10 L10,85 L93,85z" stroke-width="2" stroke-linejoin="round" fill="#fc0"
                        fill-rule="evenodd" />
                      <path d="M52,32l0,26" stroke-width="9" stroke-linecap="round" fill-rule="evenodd" />
                      <circle r="6" cx="52" cy="73" />
                    </svg>
                  </b-col>
                </b-row>
                <b-row class="flex-nowrap d-lg-none d-xl-block btnrsprow">
                  <b-col sm="6" class="padrspn">
                    <b-button size="sm" v-if="hasBeen10Minutes(item.SortDate)"
                      @click="handleDetailsClick(item.Key, showTable, item.Multicamp)" variant="info"
                      class="mr-2 btnrsp">{{ translations.tcDetails }}</b-button>
                    <b-button v-if="item.Status === `Pending` &&
                      hasBeen10Minutes(item.SortDate) &&
                      iCanSee(
                        secured_treasury_tool_pending_delete_controls.delete_treasury_tool_pending_transaction_button
                      )
                      " size="sm" @click="handleDeleteClick(item)" variant="danger" class="mr-2 btnrsp">{{
    translations.tcDelete }}</b-button>
                  </b-col>
                  <b-col sm="6" class="padrspn" v-b-popover.hover.top="helpMessagePrefix + item.Error || ''"
                    v-if="item.Error !== null && item.Error !== ''" title="Attention">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 100 100" stroke="#000">
                      <path d="M8,80s-5,8,5,9l78,0s9,0,5-9l-40-71s-4-6-8,0z" stroke-width="2" fill="#fff"
                        fill-rule="evenodd" />
                      <path d="M52,10 L10,85 L93,85z" stroke-width="2" stroke-linejoin="round" fill="#fc0"
                        fill-rule="evenodd" />
                      <path d="M52,32l0,26" stroke-width="9" stroke-linecap="round" fill-rule="evenodd" />
                      <circle r="6" cx="52" cy="73" />
                    </svg>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
          <b-container v-if="showTable === '2'">
            <b-row class="hdr up flex-nowrap">
              <b-col sm="3" md="2" lg="2" class="padrspn"><b>{{ translations.tcDate }}</b></b-col>
              <b-col sm="3" md="2" lg="2" class="padrspn"><b>{{ translations.tcType }}</b></b-col>
              <b-col sm="3" md="2" lg="2" class="padrspn"><b>{{ translations.tcStatus }}</b></b-col>
              <b-col sm="3" md="2" lg="2" class="padrspn"><b>{{ translations.tcTotal }}</b></b-col>
              <b-col sm="0" md="2" lg="2" class="d-none d-sm-block"></b-col>
            </b-row>
            <b-row class="lh54" v-if="historyItems.length === 0">
              <b-col sm="12">{{ nothingToDisplay }}</b-col>
            </b-row>
            <b-row v-for="(item, idx) in historyItems" :key="item.Key">
              <b-col sm="12">
                <b-row hover class="lh54 up flex-nowrap">
                  <b-col sm="3" md="2" lg="2" class="padrspn mobfont">
                    {{ convertDate(item.Date) }}
                  </b-col>
                  <b-col sm="3" md="2" lg="2" class="padrspn mobfont">
                    {{ item.Type }}
                  </b-col>
                  <b-col sm="3" md="2" lg="2" class="padrspn mobfont">
                    {{ item.Status }}
                  </b-col>
                  <b-col sm="3" md="2" lg="2" class="padrspn mobfont"> ${{ parseFloat(item.Total).toFixed(2) }} </b-col>
                  <b-col sm="0" md="2" lg="2" class="d-none d-sm-block">
                    <b-button v-if="item.Status === `Completed`" size="sm"
                      @click="handleViewClick(item, idx, item.multicamp)" variant="secondary" class="mr-2">{{
                        translations.tcView }}</b-button>
                    <b-button v-if="item.Status === `Pending`" size="sm"
                      @click="handleDetailsClick(item.Key, showTable, item.multicamp)" variant="info" class="mr-2">{{
                        translations.tcDetails }}</b-button>
                  </b-col>
                  <b-col sm="0" md="2" lg="2" class="d-none d-sm-block"
                    v-b-popover.hover.top="helpMessagePrefix + item.Error || ''"
                    v-if="item.Error !== null && item.Error !== ''" title="Attention">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 100 100" stroke="#000">
                      <path d="M8,80s-5,8,5,9l78,0s9,0,5-9l-40-71s-4-6-8,0z" stroke-width="2" fill="#fff"
                        fill-rule="evenodd" />
                      <path d="M52,10 L10,85 L93,85z" stroke-width="2" stroke-linejoin="round" fill="#fc0"
                        fill-rule="evenodd" />
                      <path d="M52,32l0,26" stroke-width="9" stroke-linecap="round" fill-rule="evenodd" />
                      <circle r="6" cx="52" cy="73" />
                    </svg>
                  </b-col>
                </b-row>
                <b-row class="flex-nowrap d-lg-none d-xl-block btnrsprow">
                  <b-col sm="6" class="padrspn">
                    <b-button v-if="item.Status === `Completed`" size="sm"
                      @click="handleViewClick(item, idx, item.multicamp)" variant="secondary" class="mb10 btnrsp">{{
                        translations.tcView }}</b-button>
                    <b-button v-if="item.Status === `Pending`" size="sm"
                      @click="handleDetailsClick(item.Key, showTable, item.multicamp)" variant="info"
                      class="mb10 btnrsp">{{ translations.tcDetails }}</b-button>
                  </b-col>
                  <b-col sm="6" v-b-popover.hover.top="helpMessagePrefix + item.Error || ''"
                    v-if="item.Error !== null && item.Error !== ''" title="Attention">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 100 100" stroke="#000">
                      <path d="M8,80s-5,8,5,9l78,0s9,0,5-9l-40-71s-4-6-8,0z" stroke-width="2" fill="#fff"
                        fill-rule="evenodd" />
                      <path d="M52,10 L10,85 L93,85z" stroke-width="2" stroke-linejoin="round" fill="#fc0"
                        fill-rule="evenodd" />
                      <path d="M52,32l0,26" stroke-width="9" stroke-linecap="round" fill-rule="evenodd" />
                      <circle r="6" cx="52" cy="73" />
                    </svg>
                  </b-col>
                </b-row>
                <b-container v-if="!!selectedSummaryItems[0] && showDetail && row === idx"
                  style="margin: 20px 0; color: #08597f">
                  <b-row>
                    <b-col sm="2"><b>{{ translations.tcDetailView }}</b></b-col>
                    <b-col sm="4">{{ translations.tcCamp }}</b-col>
                    <b-col sm="1">{{ translations.tcAmount }}</b-col>
                  </b-row>
                  <b-row v-for="detail in selectedSummaryItems[0].SummaryDetail" :key="detail.rmd_key">
                    <b-col sm="2"></b-col>
                    <b-col sm="1">{{ detail.cmp_number }}</b-col>
                    <b-col sm="3">{{ detail.camp_org_name }}</b-col>
                    <b-col sm="1">${{ parseFloat(detail.rmd_receipt_amount).toFixed(2) }}</b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-container>
          <AddSummary :modalTitle="campTitle" :multicamp="multicamp" :i18n="translations.components"
            :translations="translations" @continueToPayment="processPayment($event)" />
          <GenericVerify :title="modal_verbiage.t" :verbiage_a="modal_verbiage.v_a" :verbiage_b="modal_verbiage.v_b"
            :num="deleteKey" @modalFollowUp="handleModalFollowUp($event)" />
          <HistoryList :modalTitle="translations.tcCompletedRemittance" :translations="translations" :status="plstatus" />
        </section>
      </page-body>
      <page-footer class="mx-lg">
        <b-button class="btn btn-tertiary btn-w-med" @click="handleBackClick">{{ translations.tcBack }}</b-button>
      </page-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, mapState } from 'vuex'
import AddSummary from '@/components/treasury-tools/add_summary'
import { alertMixin } from '@/mixins/alertMixin'
import { remittanceMixin } from '@/mixins/remittanceMixin'
import GenericVerify from '@/components/treasury-tools/generic_verify'
import HistoryList from '@/components/treasury-tools/history_list'
import Loader from '@/components/Loader.vue'
import { modalMixin } from '@/mixins/modalMixin'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  components: {
    AddSummary,
    GenericVerify,
    Loader,
    pageBody,
    pageFooter,
    HistoryList,
    securityBanner: SecurityBanner
  },
  mixins: [alertMixin, remittanceMixin, modalMixin, numericMixin, translationMixin],
  name: 'pending-remittance',
  data() {
    return {
      fundType: '',
      showDecadeNav: true,
      hideHeader: true,
      showTable: 0,
      row: -1,
      showDetail: false,
      deleteKey: null,
      helpMessagePrefix: `An error occurred during the processing of this transaction. The transaction may need to be resubmitted. Please call headquarters for assistance. \nActual error message: `,
      historyItems: [],
      line: {
        remittanceDate: '',
        remittanceKey: '',
        remittanceDetailKey: '0'
      },
      modal_verbiage: {
        t: 'Confirm the Delete',
        v_a: 'Are you sure you want to delete the pending remittance dated {0} with a total of {1}?',
        v_b: 'If so, click Continue. Otherwise, click Cancel.'
      },
      multicamp: false,
      nothingToDisplay: 'Enter a date range above and click the Submit button.',
      plstatus: 'Selected',
      selectedDateRange: {
        start: null,
        end: null
      },
      summaryItems: [],
      secured_treasury_tool_pending_delete_controls: {
        delete_treasury_tool_pending_transaction_button: 'f24570de-05d0-462b-8a62-e13c2b6596d0'
      },
      secured_tile_view_controls: {
        treasury_state_pending_scripture_remittance_tile: '3ee172c5-3cc8-4663-b13d-6a9b64577b7d',
        treasury_camp_pending_scripture_remittance_tile: '902438de-ea05-4c61-b6cc-29fe2bace599',
        treasury_state_history_scripture_remittance_tile: '60eb31ea-0029-49b0-9c1f-c039f223248b',
        treasury_camp_history_scripture_remittance_tile: 'a1f03eb3-b291-4e90-93a8-d27bcd67516e'
      },
      translations: {},
      dateRangeText: 'Select a date range'
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations('funds'),
        this.getComponentTranslations('security-banner', 'add-summary')
      ]).then(results => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.helpMessagePrefix = this.translations.tcHelpMessagePrefix
        this.modal_verbiage.t = this.translations.tcConfirmDelete
        this.modal_verbiage.v_a = this.translations.tcConfirmDeletePendingRemittance
        this.modal_verbiage.v_b = this.translations.tcConfirmDeleteContinueCancel
        this.nothingToDisplay = `${this.translations.tcEnterDateRange}.`
        this.dateRangeText = this.translations.tcSelectDateRange
      })
      // await (async () => {
      //   await this.setHomeCamp({
      //     key: this.stateOrCamp === 's' ? this.stateUndist.cmp_org_key : this.userCamp.key,
      //     text: this.stateOrCamp === 's' ? this.stateUndist.camp_name : this.userCamp.name,
      //     value: this.stateOrCamp === 's' ? this.stateUndist.camp_number : this.userCamp.number
      //   })
      // })()
      await (this.showTable = this.$route.path.split('/')[5] === 'menu' ? '0' : this.$route.path.split('/')[5])
      await this.loadView()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
      if (this.remittanceTimeRemaining(this.remittanceProcessedTime) > 0 && this.showTable === '1') {
        await this.showRemittanceInProcessMessage()
      }
    }
  },

  computed: {
    ...mapGetters({
      currentCamp: 'campModule/currentCamp',
      iCanSee: 'user/iCanSee',
      multicampPendings: 'remittanceModule/multicampPendings',
      pendings: 'remittanceModule/pendings',
      pendingRemittances: 'remittanceModule/pendingRemittances',
      prefCulture: 'user/userPreferredCulture',
      remittanceHistoryItems: 'remittanceModule/remittanceHistoryItems',
      selectedSummaryItems: 'remittanceModule/selectedSummary',
      stateOrCamp: 'campModule/stateOrCamp',
      stateUndist: 'campModule/stateUndist',
      userCamp: 'user/userCamp',
      userId: 'user/userId'
    }),
    ...mapState({
      remittanceProcessedTime: state => state.remittanceModule.remittanceProcessedTime
    }),
    allowPage() {
      return this.stateOrCamp === 's'
        ? this.showTable === '1'
          ? this.iCanSee(this.secured_tile_view_controls.treasury_state_pending_scripture_remittance_tile)
          : this.iCanSee(this.secured_tile_view_controls.treasury_state_history_scripture_remittance_tile)
        : this.showTable === '1'
          ? this.iCanSee(this.secured_tile_view_controls.treasury_camp_pending_scripture_remittance_tile)
          : this.iCanSee(this.secured_tile_view_controls.treasury_camp_history_scripture_remittance_tile)
    },
    campTitle() {
      let camptitle = '(' + this.currentCamp.value + ') ' + this.currentCamp.text
      return this.multicamp ? camptitle + ' - ' + this.fundType : camptitle
    },
    stateOrCampKey() {
      return this.stateOrCamp === 's' ? this.stateUndist.cmp_org_key : this.currentCamp.key
    },
    minimumDate() {
      const tday = new Date()
      const thirdYearAgo = tday.getFullYear() - 3
      tday.setFullYear(thirdYearAgo)
      return new Date(tday.getFullYear(), tday.getMonth() + 1, tday.getDate())
    },
    readyToSubmit() {
      return this.selectedDateRange.start !== null && this.selectedDateRange.end !== null
    },
    pageTitle() {
      if (!this.showTable) return this.translations.tcTitleScriptureFundDonations
      else if (this.showTable === '1') return this.stateOrCamp === 's' ? this.translations.tcTitleStatePendingScriptureFundDonations : this.translations.tcTitlePendingScriptureFundDonations
      else if (this.showTable === '2') return this.stateOrCamp === 's' ? this.translations.tcTitleStateScriptureFundDonationsHistory : this.translations.tcTitleScriptureFundDonationsHistory
      else return this.translations.tcTitleScriptureFundDonations
    }
  },
  methods: {
    ...mapActions({
      deleteRemittance: 'remittanceModule/deleteRemittance',
      getPendingDetailsByOrgAndKey: 'remittanceModule/getPendingDetailsByOrgAndKey',
      getPendingRemittancesForOrg: 'remittanceModule/getPendingRemittancesForOrg',
      getRemittanceHistoryByDate: 'remittanceModule/getRemittanceHistoryByDate',
      getRemittanceHistoryTop10: 'remittanceModule/getRemittanceHistoryTop10',
      getSummaryByOrg: 'remittanceModule/getSummaryByOrg',
      processRemittance: 'remittanceModule/processRemittance',
      resubmitRemittance: 'remittanceModule/resubmitRemittance',
      savePendingTransaction: 'remittanceModule/savePendingTransaction',
      setHomeCamp: 'campModule/setHomeCamp',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPresentationSummaryItems: 'remittanceModule/setPresentationSummaryItems',
      setSummaryItems: 'remittanceModule/setSummaryItems',
      setSummaryForMulticampItems: 'remittanceModule/setSummaryForMulticampItems'
    }),
    handleDeleteClick(itm) {
      this.modal_verbiage.v_a = this.modal_verbiage.v_a
        .replace('{0}', itm.Date)
        .replace('{1}', this.returnAsCurrency(itm.Total))
      this.deleteKey = itm.Key
      this.showModal(itm, 'genericVerifyModal')
    },
    async handleDetailsClick(key, show, multicamp) {
      this.line.remittanceKey = key
      this.multicamp = multicamp
      await this.getPendingDetailsByOrgAndKey({
        org_key: this.stateOrCampKey,
        rmt_key: key,
        multicamp: multicamp
      }).then(() => {
        let summaryLineItems = []
        let summaryCounts = []
        let pendingTrans = multicamp ? this.multicampPendings : this.pendings
        if (multicamp) {
          pendingTrans.map(pt => {
            pt.camp.map(beta => {
              summaryLineItems.push({
                camp_name: '(' + beta.camp_number + ') ' + beta.camp_name,
                camp_key: beta.key, //camp key
                amount: beta.amount, //amount
                order: beta.camp_name,
                fundType: pt.field.value,
                countText: pt.field.countText,
                countField: pt.field.countField,
                count: beta.count,
                rmdKey: beta.camp_rmd_key
              })
              this.fundType =
                beta.count > 0
                  ? this.translations[pt.field.text] + '/' + this.translations[pt.field.countText]
                  : this.translations[pt.field.text]
            })
          })
          this.setSummaryForMulticampItems(summaryLineItems)
        } else {
          pendingTrans.map(pt => {
            pt.transactions.map(alpha => {
              return alpha.accounts.map(beta => {
                if (beta.currency === true && beta.amount > 0.0) {
                  summaryLineItems.push({
                    group: alpha.group,
                    category: beta.category,
                    field: beta.field,
                    amount: beta.amount,
                    order: beta.order
                  })
                }
              })
            })
          })
          pendingTrans.map(pt => {
            pt.transactions.map(gamma => {
              return gamma.accounts.map(delta => {
                if (delta.currency !== true && delta.amount > 0) {
                  summaryCounts.push({
                    category: delta.category,
                    field: delta.field,
                    amount: delta.amount,
                    group: gamma.group
                  })
                }
              })
            })
          })
          this.setSummaryItems(summaryLineItems)
          this.setPresentationSummaryItems(summaryCounts)
        }
        this.showModal(null, 'addSummaryModal', 'summaryModal')
      })
    },
    handleDateRangeConfirm(evt) {
      this.selectedDateRange.start = evt.start.replace(/\//g, '-')
      this.selectedDateRange.end = evt.end.replace(/\//g, '-')
    },
    async handleInitialHistoryLoad() {
      await this.getRemittanceHistoryTop10(this.stateOrCampKey).then(() => {
        if (!this.translations || !this.remittanceHistoryItems) return false
        this.historyItems = this.remittanceHistoryItems.map(history => {
          // translate history type
          const historyType = `tc${history.Type.replace(/ /g, '')}`
          const typeText = this.translations.hasOwnProperty(historyType) ? this.translations[historyType] : history.Type

          // translate history status
          const historyStatus = `tc${history.Status.replace(/ /g, '')}`
          const statusText = this.translations.hasOwnProperty(historyStatus)
            ? this.translations[historyStatus]
            : history.Status

          return { ...history, Type: typeText, Status: statusText }
        })

        this.nothingToDisplay =
          this.historyItems.length === 0
            ? `${this.translations.tcNoDataForDateRange}. `
            : `${this.translations.tcEnterDateRange}.`
      })
    },
    async handleHistoryRequest() {
      try {
        this.setLoadingStatus(true)
        await this.getRemittanceHistoryByDate({
          org_key: this.stateOrCampKey,
          start_date: this.selectedDateRange.start,
          end_date: this.selectedDateRange.end
        }).then(() => {
          this.historyItems = this.remittanceHistoryItems.map(history => {
            // translate history type
            const historyType = `tc${history.Type.replace(/ /g, '')}`
            const typeText = this.translations.hasOwnProperty(historyType)
              ? this.translations[historyType]
              : history.Type

            // translate history status
            const historyStatus = `tc${history.Status.replace(/ /g, '')}`
            const statusText = this.translations.hasOwnProperty(historyStatus)
              ? this.translations[historyStatus]
              : history.Status

            return { ...history, Type: typeText, Status: statusText }
          })
          this.nothingToDisplay =
            this.historyItems.length === 0
              ? `${this.translations.tcNoDataForDateRange}. `
              : `${this.translations.tcEnterDateRange}.`
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleModalFollowUp() {
      try {
        this.setLoadingStatus(true)
        await this.deleteRemittance({
          rmt_key: this.deleteKey,
          ind_key: this.userId,
          tdt: this.formattedDate()
        }).then(() => {
          this.summaryItems = this.pendingRemittances
          this.showAlert('warning', `${this.translations.tcPendingTransactionDeleted}.`)
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    handleBackClick() {
      this.$router.push({ path: `/officers/camp/treasury-tools/${this.stateOrCamp}` })
    },
    async handleViewClick(itm, row, multicamp) {
      this.multicamp = multicamp
      if (row === this.row) {
        this.row = -1
      } else {
        await this.getSummaryByOrg({
          org_key: this.stateOrCampKey,
          status: itm.Status,
          rmt_key: itm.Key
        }).then(() => {
          this.showModal(null, 'historyListModal')
        })
      }
    },
    hasBeen10Minutes(dateTimeStr) {
      const dateTime = new Date(dateTimeStr)
      const now = new Date()
      const tenMinutesAgo = new Date(now.getTime() - 10 * 60 * 1000)
      return dateTime <= tenMinutesAgo
    },
    convertDate(date) {
      var dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
      var returnDate = new Date(date)
      return returnDate.toLocaleDateString(this.prefCulture, dateOptions)
    },
    async loadView() {
      if (this.showTable === '1') {
        await this.getPendingRemittancesForOrg(this.stateOrCampKey)
        if (!this.translations || !this.pendingRemittances) return false
        this.summaryItems = this.pendingRemittances.map(rem => {
          // type translation
          const remType = `tc${rem.Type.replace(/ /g, '')}`
          const typeText = this.translations.hasOwnProperty(remType) ? this.translations[remType] : rem.Type

          // status translation
          const remStatus = `tc${rem.Status.replace(/ /g, '')}`
          const statusText = this.translations.hasOwnProperty(remStatus) ? this.translations[remStatus] : rem.Status

          return { ...rem, Type: typeText, Status: statusText }
        })
      } else {
        await this.handleInitialHistoryLoad()
      }
    },
    async processPayment() {
      const remitKey = this.line.remittanceKey
      this.$swal({
        icon: 'success',
        text: this.translations.tcRemittanceProcessingMessage,
        confirmButtonText: this.translations.tcOk
      }).then(result => {
        this.$router.push({ path: `/officers/camp/treasury-tools/${this.stateOrCamp}` })
      })
      if (remitKey != 0) {
        this.resubmitRemittance(remitKey)
      }
    },
    async showRemittanceInProcessMessage() {
      let timeRemaining = await this.remittanceTimeRemaining(this.remittanceProcessedTime)
      this.$swal({
        icon: 'error',
        text: this.translations.tcRemittanceInProcessMessage + timeRemaining + ' ' + this.translations.tcMinutes,
        confirmButtonText: this.translations.tcOk
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

#startDate.btn,
#endDate.btn {
  min-width: 75px !important;
}

.campname {
  font-size: 22px;
}

.dp-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @include breakpoint(sm) {
    width: 100%;
  }

  .dp-startend {
    width: 20%;
    display: inline-block;

    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

.contact_date {
  width: 100%;

  @include breakpoint(sm) {
    width: 100%;
  }
}

.mb10 {
  margin-bottom: 10px;
}

.mtb30 {
  margin: 30px 0;
}

.ml30 {
  margin-bottom: 30px;
}

.lh54 {
  line-height: 54px;

  @include breakpoint(sm) {
    line-height: 30px;
    white-space: nowrap;
  }
}

.nonew {
  padding: 30px;
}

.mobfont {
  @include breakpoint(sm) {
    font-size: 14px;
  }
}

.btnrsprow {
  margin-bottom: 40px;
}

.btnrsp {
  min-width: 100px;
  display: inline-block;
  // position: absolute;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 100;
  letter-spacing: 0px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: bold;
}

.padrspn {
  @include breakpoint(sm) {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

.membership {
  .page-body {
    margin-top: 60px;

    h2 {
      margin-bottom: 36px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 52px;

    >.row {
      margin-bottom: 40px;
    }

    .t-container {
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }

    .col {
      @include breakpoint(sm) {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;

        .number-card:last-of-type {
          margin-bottom: 0;
        }
      }

      table {
        width: 100% !important;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
