<template>
  <b-modal
    class="bmodal"
    size="lg"
    id="historyListModal"
    ref="modalHistoryList"
    :ok-title="i18n['HistoryList'].tcOk"
    :title="modalTitle"
    ok-only
    :no-close-on-backdrop="noCloseOnBackdrop"
    @ok="handleClickOk"
  >
    <b-container class="twelve" v-for="(prw, idx) in displaySummary" :key="`k${idx}`" :id="`k${idx}`">
      <b-row style="margin-bottom: 20px;">
        <b-col v-if="!multicamp" sm="9"
          ><div class="instr">{{ i18n['HistoryList'].tcClickTheCampNameToViewDetails }}</div>
        </b-col>
        <b-col v-else sm="9"
          ><div class="instr fund-name">
            <span v-html="fundDetail"></span>
          </div>
        </b-col>

        <b-col sm="3" class="text-right">
          <b-button class="printBtn" @click.prevent="handlePrintRequest({ prw: prw })" type="application/pdf">{{
            i18n['HistoryList'].tcPrint
          }}</b-button>
        </b-col>
      </b-row>
      <b-row class="bb">
        <b-col sm="8" class="dateclass">{{ prw.rmt_date }}</b-col>
        <b-col sm="2" class="d-none d-lg-block text-right">
          <b>{{ i18n['HistoryList'].tcDonations }}</b>
        </b-col>
        <b-col sm="1" class="d-none d-lg-block">
          <b>{{ i18n['HistoryList'].tcCounts }}</b>
        </b-col>
      </b-row>
      <div v-for="(summary, inx) in prw.SummaryDetail" :key="`g${inx}`">
        <b-row>
          <b-col sm="1"></b-col>
          <b-col
            sm="7"
            :class="multicamp ? '' : 'camp-cursor'"
            class="camp"
            @click="handleItemClick(prw.rmt_key, summary.rmd_cmp_org_key, prw.rmt_date)"
          >
            <b>{{ summary.camp_org_name }} {{ i18n['HistoryList'].tcCamp }} - {{ summary.cmp_number }}</b>
          </b-col>
          <b-col sm="2" class="text-right"
            ><span class="d-sm-block d-lg-none">{{ i18n['HistoryList'].tcDonations }} </span
            >{{ summary.rmd_receipt_amount | toCurrency }}</b-col
          >
          <b-col sm="1" class="text-right"
            ><span class="d-sm-block d-lg-none"> {{ i18n['HistoryList'].tcCounts }} </span
            >{{ summary.rst_count_sum }}</b-col
          >
        </b-row>
        <HistoryDetails
          :displayRmtKey="displayRmtKey"
          :displayRmdKey="displayRmdKey"
          :rmtKey="prw.rmt_key"
          :rmdKey="summary.rmd_cmp_org_key"
          :historyDetails="activeDetails"
        />
      </div>
    </b-container>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import HistoryDetails from './history_details'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'

export default {
  name: 'HistoryList',
  mixins: [reportDownloadMixin],
  components: {
    HistoryDetails
  },
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          HistoryList: {
            tcCamp: 'Camp',
            tcClickTheCampNameToViewDetails: 'Click the camp name to view details.',
            tcCounts: 'Counts',
            tcDonations: 'Donations',
            tcOk: 'Ok',
            tcPrint: 'Print'
          }
        }
      }
    },
    modalTitle: {
      type: String,
      default: 'Pending Remittances'
    },
    status: {
      type: String,
      default: 'Pending'
    },
    translations: {
      type: Object
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeDetails: [],
      click: true,
      displayRmtKey: '',
      displayRmdKey: '',
      fundDetail: '',
      multicamp: false,
      noCloseOnBackdrop: true,
      raw_pdf_link: 'RemittanceSummary?org_key=ok&status=tk&rmt_key=rk'
    }
  },
  computed: {
    ...mapGetters({
      completedSummary: 'remittanceModule/completedSummary',
      multicampRemittanceItems: 'remittanceModule/multicampRemittanceItems',
      pendingSummary: 'remittanceModule/pendingSummary',
      selectedSummary: 'remittanceModule/selectedSummary',
      selectSummaryDetails: 'remittanceModule/selectSummaryDetails'
    }),
    displaySummary() {
      let fundObj = null
      let summary =
        this.status === 'Pending'
          ? this.pendingSummary
          : this.status === 'Completed'
          ? [...this.completedSummary, ...this.selectedSummary]
          : this.selectedSummary
      this.fundDetail = ''
      if (summary.length > 0) {
        this.fundDetail = summary[0] ? this.translations[summary[0].rmt_fund_name] : ''
        this.multicamp = summary[0].rmt_multi_camp
        if (
          summary[0].rmt_multi_camp &&
          (summary[0].rmt_fund_name == 'tcChurchReceipts' || summary[0].rmt_fund_name == 'tcGideonCardReceipts')
        ) {
          fundObj = this.multicampRemittanceItems.filter(obj => {
            return obj.text === summary[0].rmt_fund_name
          })
          this.fundDetail += '/' + this.translations[fundObj[0].countText]
        }
      }
      return summary
    }
  },
  methods: {
    ...mapActions({
      getSummaryDetails: 'remittanceModule/getSummaryDetails'
    }),
    closeModal() {
      this.$refs.modalHistoryList.hide()
    },
    handleClickOk() {
      this.closeModal()
    },
    async handleItemClick(rmtkey, rmdkey, rmtdate) {
      if (this.multicamp) return
      if (this.displayRmtKey === rmtkey && this.displayRmdKey === rmdkey) {
        this.displayRmtKey = ''
        this.displayRmdKey = ''
        return
      }
      this.displayRmtKey = rmtkey
      this.displayRmdKey = rmdkey
      const parms = {
        org_key: rmdkey,
        rmt_date: rmtdate,
        rmt_key: rmtkey,
        multicamp: this.multicamp,
        status: 'Completed'
      }
      this.activeDetails = await this.selectSummaryDetails(rmtkey, rmdkey)
      if (this.activeDetails.length === 0) {
        await this.getSummaryDetails(parms)
        this.activeDetails = await this.selectSummaryDetails(rmtkey, rmdkey)
      }
    },
    handlePrintRequest(info) {
      if (info) {
        var reportDownloadUrl = this.raw_pdf_link
          .replace('rk', info.prw.rmt_key)
          .replace('tk', info.prw.rmt_type)
          .replace('ok', info.prw.rmt_org_key)
          .replace(/&amp;/g, '&')
        this.downloadReport(reportDownloadUrl, 'RemittanceSummary', 'pdf')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

hr {
  margin: 2px;
}

.twelve {
  font-size: 16px;
  margin-bottom: 20px;
}

.short {
  margin-bottom: 0;
}
.camp-cursor {
  cursor: pointer;
}
.camp {
  padding: 3px 15px;

  @include breakpoint(sm) {
    padding: 3px 0;
  }
}

.camp:hover {
  background-color: #ddd;
}

.dateclass {
  color: #15a927;
  font-weight: 500;
}

.instr {
  margin: 4px 0 0 0;
}
.fund-name {
  font-weight: bold;
  font-size: 22px;
}

.bb {
  border-bottom: 1px solid #000;
}

.ml {
  margin-left: 35px;

  @include breakpoint(sm) {
    margin-left: 0px;
  }
}
.printBtn {
  padding: 0px 6px;
  font-size: 14px;
  border-radius: 2px;
  min-width: 100px;
}
</style>
